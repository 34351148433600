/* Custom Tree Inner
 *
 * This style will set the correct size of the scroll-component
 * and trigger the vertical scroll when needed.
 */

/* .tree-inner-wrap { */
/*   flex: 1 1 auto; */
/*   width: 100%; */
/* } */

.action {
  cursor: pointer;
  display: table-cell;
  padding-left: 4px;
  float: right;
  margin-left: auto;
}

.action:hover {
  color: green;
}

.infinite-scroll-component {
  width: max-content;
}
