div.tooltip {
  position: absolute;
  text-align: center;
  padding: 0.2rem;
  background: #313639;
  color: #f9f9f9;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  font-size: 0.7rem;
}
