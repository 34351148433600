* {
  box-sizing: border-box;
}

body {
  color: #383f4d;
  line-height: 1.5;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    sans-serif;
}

/* 
=================
Table
=====================
*/

.table_container {
  max-width: 750px;
  /* max-height: 500px; */
  margin: 0 auto;
  overflow: auto;
}

.sctable {
  width: 100%;
  border-spacing: 0;
}

caption {
  font-style: italic;
  font-size: 90%;
  margin-bottom: 10px;
}

.sctable th {
  background: #fff;
  padding: 8px 5px;
  border-bottom: 1px solid #1a1a1a;
  font-weight: bold;
  text-align: left;
}

.sctable th > pre {
  margin: 0;
}

.sctable th.up {
  background-image: url("../../images/up_arrow.png");
}
.sctable th.down {
  background-image: url("../../images/down_arrow.png");
}
.sctable th.default {
  background-image: url("../../images/default.png");
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.sctable td {
  border-top: 1px solid #ddd;
  padding: 8px 5px;
}

.sctable tbody tr:first-child td {
  border-top: none;
}

.sctable tbody tr:nth-child(n) td {
  background: #eff0f0;
}

.sctable tbody tr:nth-child(2n) td {
  background: #fff;
}

/* for dots */

.dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

/* for instant tooltip */

.sctooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.sctooltip .sctooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: eeeeee;
  color: #606060;
  text-align: center;
  border-radius: 6px;
  padding: 15px 0px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.sctooltip:hover .sctooltiptext {
  visibility: visible;
}

/* for legend */
.dotlegend {
  display: inline-block;
  width: 30%;
  text-align: center;
}

/* for tooltip as tooltip */

.regexptooltip {
  /* position: relative; */
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}
.regexptooltip .regexptooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: eeeeee;
  color: #606060;
  text-align: center;
  border-radius: 6px;
  padding: 0 px 0px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.regexptooltip:hover .regexptooltiptext {
  visibility: visible;
}
